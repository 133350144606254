import { AccountsApi, UserAuthRead } from "@apis/typescript-axios"
import {
	Box,
	Button,
	Center,
	Divider,
	FormControl,
	FormLabel,
	HStack,
	Heading,
	Input,
	Spinner,
	Text,
	VStack
} from "@chakra-ui/react"
import { ButtonWithCustomLoadingComponent } from "@components/ButtonWithCustomLoadingComponent"
import { ErrorMessage } from "@components/ErrorMessage"
import { LinkButton } from "@components/LinkButton"
import { PasswordInput } from "@components/PasswordInput"
import { TextWithFurigana } from "@components/TextWithFurigana"
import { FullScreenCenteringLayout } from "@components/_layouts/FullScreenCenteringLayout"
import { ForForgotPasswordUserLink } from "@components/login/ForForgotPasswordUserLink"
import { CONFIG, isNotProd } from "@constants/const"
import { GoogleButton } from "@features/sso/google/GoogleButton"
import { MicrosoftButton } from "@features/sso/microsoft/MicrosoftButton"
import {
	GOOGLE_PROVIDER_META,
	MICROSOFT_PROVIDER_META,
	ProviderMeta
} from "@features/sso/providers"
import { sleep } from "@helpers/sleep"
import { useAuth } from "@hooks/useAuth"
import { useErrorToast } from "@hooks/useErrorToast"
import { useRedirectIfLoggedIn } from "@hooks/useRedirectIfLoggedIn"
import { useRedirectIfNeeded } from "@hooks/useRedirectIfNeeded"
import { ApiParams, useServerErrors } from "@hooks/useServerErrors"
import { colors } from "@styles/colors"
import { AnalyticsLog } from "@utils/analytics/amplitude"
import axios from "axios"
import { signInWithPopup } from "firebase/auth"
import { useRouter } from "next/router"
import { FC, KeyboardEvent, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { firebaseAuth } from "../../apis/firebase"

type FormData = {
	email: string
	password: string
}

const getAccountsApi = () => new AccountsApi()

const LoginScreen: FC = () => {
	const router = useRouter()
	const emailFromUrl = (router.query.email as string) || undefined
	return router.isReady ? (
		<LoginScreenBase defaultEmail={emailFromUrl} />
	) : (
		<Center p={10}>
			<Spinner />
		</Center>
	)
}

type Props = {
	defaultEmail: string | undefined
}

const LoginScreenBase: FC<Props> = ({ defaultEmail }) => {
	const [isLoggingIn, setIsLoggingIn] = useState(false)
	const { redirect } = useRedirectIfNeeded()
	const { addErrorToast } = useErrorToast()

	const {
		handleSubmit,
		watch,
		control,
		formState: { errors },
		setValue
	} = useForm<FormData>({
		defaultValues: {
			email: defaultEmail
		}
	})

	useEffect(() => {
		setErrorMessage("")
	}, [watch("password"), watch("email")])

	const [errorMessage, setErrorMessage] = useState("")
	const { setUserInfo } = useAuth()

	useRedirectIfLoggedIn()

	const { updateErrorComponentDict, errorComponentDict } = useServerErrors<
		ApiParams<AccountsApi, "login"> | "nonFieldErrors"
	>()

	const onClickEnterToSubmit = (e: KeyboardEvent) => {
		if (e.key == "Enter") {
			handleSubmit(login)
		}
	}

	const handleAfterLogIn = (userData: UserAuthRead) => {
		setUserInfo(userData)
		AnalyticsLog.setUserPropertiesToLogger({
			id: userData.user.id,
			roleId: userData.user.roleId,
			roleType: userData.user.roleType
		})
		redirect(userData)
	}

	const login = async (data: FormData) => {
		setIsLoggingIn(true)
		try {
			AnalyticsLog.logActionEvent("login")
			const { data: userData } = await getAccountsApi().login(data)
			handleAfterLogIn(userData)
		} catch (error: any) {
			AnalyticsLog.logErrorFromActionEvent("login", error?.response?.detail)
			updateErrorComponentDict(error)
			if (error?.response?.status === 404) {
				setErrorMessage(
					"メールアドレスとパスワードのいずれか、もしくは両方が間違っています"
				)
			} else if (500 <= error?.response?.status) {
				setErrorMessage(
					"サーバーエラーが起きました。\nお手数ですが、時間をおいてお試しください"
				)
			} else {
				setErrorMessage(
					`なんらかのエラーが発生しました。\n通信状況などをご確認の上、再度お試しください`
				)
			}
		} finally {
			setIsLoggingIn(false)
		}
	}

	const loginWith = async (providerMeta: ProviderMeta) => {
		try {
			const provider = providerMeta.providerFactory()
			const res = await signInWithPopup(firebaseAuth, provider)
			if (res) {
				setIsLoggingIn(true)
				const idToken = await firebaseAuth.currentUser?.getIdToken()

				if (!idToken) return
				const { data: userData } = await getAccountsApi().loginByIdToken({
					idToken
				})
				handleAfterLogIn(userData)
			}
		} catch (error) {
			console.error(error)
			try {
				firebaseAuth.signOut()
			} catch (signOutError) {
				console.error(signOutError)
			}
			addErrorToast({
				error,
				// addServerErrorMessages: true,
				additionalTextDirective: {
					404: `ユーザーが存在しないか、${providerMeta.label}アカウントと連携されていない可能性があります。`
				},
				baseText: `${providerMeta.label}アカウントでのログインに失敗しました`
			})
		} finally {
			setIsLoggingIn(false)
		}
	}

	const getClassrooms = (accessToken: string) => {
		const endpoint = "https://classroom.googleapis.com/v1/courses"

		// axiosを使用してClassroom APIにアクセス
		axios
			.get(endpoint, {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			})
			.then((response) => {
				console.log(response.data)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const getStudents = (accessToken: string) => {
		const endpoint =
			"https://classroom.googleapis.com/v1/courses/518750398697/students"

		// axiosを使用してClassroom APIにアクセス
		axios
			.get(endpoint, {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			})
			.then((response) => {
				console.log(response.data)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const DevLoginButtons = isNotProd && (
		<>
			<Button
				mt={12}
				py={3}
				w="100%"
				onClick={async () => {
					setValue("email", "teacher@example.com")
					if (CONFIG.ENVIRONMENT === "local") {
						setValue("password", "password")
					}
					await sleep(100)
					handleSubmit(login)()
				}}
				isLoading={isLoggingIn}
				loadingText="ログイン中…"
			>
				教師1でログイン
			</Button>
			<Button
				mt={12}
				py={3}
				w="100%"
				onClick={async () => {
					setValue("email", "student@example.com")
					if (CONFIG.ENVIRONMENT === "local") {
						setValue("password", "password")
					}
					await sleep(100)
					handleSubmit(login)()
				}}
				isLoading={isLoggingIn}
				loadingText="ログイン中…"
			>
				児童1でログイン
			</Button>
			<Button
				mt={12}
				py={12}
				w="100%"
				onClick={async () => {
					setValue("email", "patron@example.com")
					if (CONFIG.ENVIRONMENT === "local") {
						setValue("password", "password")
					}
					await sleep(100)
					handleSubmit(login)()
				}}
				isLoading={isLoggingIn}
				loadingText="ログイン中…"
			>
				保護者1でログイン
			</Button>
			<Button
				mt={12}
				py={3}
				w="100%"
				onClick={async () => {
					setValue("email", "6526823709881_student@example.com")
					if (CONFIG.ENVIRONMENT === "local") {
						setValue("password", "password")
					}
					await sleep(100)
					handleSubmit(login)()
				}}
				isLoading={isLoggingIn}
				loadingText="ログイン中…"
			>
				児童2でログイン
			</Button>
		</>
	)

	const GoogleButtonComponent = (
		<GoogleButton
			type="submit"
			onClick={() => loginWith(GOOGLE_PROVIDER_META)}
			isLoading={isLoggingIn}
		>
			<TextWithFurigana furigana="ぐーぐるでろぐいん" color={"#fff"} bold>
				Googleでログイン
			</TextWithFurigana>
		</GoogleButton>
	)

	const MicrosoftButtonComponent = (
		<MicrosoftButton
			type="submit"
			onClick={() => loginWith(MICROSOFT_PROVIDER_META)}
			isLoading={isLoggingIn}
		>
			<TextWithFurigana furigana="まいくろそふとでろぐいん" bold>
				Microsoftでログイン
			</TextWithFurigana>
		</MicrosoftButton>
	)

	const Separator = (
		<HStack w="100%" py={10}>
			<Divider />
			<Text
				px={3}
				color={colors.text.black[400]}
				bg="#fff"
				flexWrap={"nowrap"}
				whiteSpace="nowrap"
			>
				または
			</Text>
			<Divider />
		</HStack>
	)

	return (
		<FullScreenCenteringLayout>
			<VStack w="100%" justifyContent="center" px={3} py={6} gap={10}>
				<Heading alignSelf={"center"}>ClassCloudへログイン</Heading>
				<VStack gap={4} mt={16} w="100%" maxW="400px" margin="auto">
					{!defaultEmail && (
						<>
							{GoogleButtonComponent}
							{MicrosoftButtonComponent}
							{Separator}
						</>
					)}
					<form onSubmit={handleSubmit(login)}>
						<VStack
							gap={4}
							mt={16}
							w="100%"
							minW={{ base: "300px", md: "400px" }}
							maxW="400px"
							margin="auto"
						>
							<VStack w="100%">
								<Controller
									control={control}
									rules={{
										required: "この項目は必須です",
										pattern: {
											value: /\S+@\S+\.\S+/,
											message: "メールアドレスの形式が有効ではありません"
										}
									}}
									render={({ field: { onChange } }) => (
										<FormControl isInvalid={!!errors.email}>
											<FormLabel>メールアドレス</FormLabel>
											<Input
												w="100%"
												autoCapitalize="none"
												autoFocus
												onChange={onChange}
												defaultValue={defaultEmail}
												placeholder="sample@example.com"
												onKeyDown={(e) => onClickEnterToSubmit(e)}
											/>
										</FormControl>
									)}
									name="email"
								/>
								<ErrorMessage>{errors.email?.message}</ErrorMessage>
								{errorComponentDict.email}
							</VStack>
							<VStack w="100%">
								<Controller
									control={control}
									rules={{
										required: "この項目は必須です",
										minLength: {
											value: 8,
											message: "8文字以上です"
										}
									}}
									render={({ field: { onChange } }) => (
										<FormControl isInvalid={!!errors.password}>
											<FormLabel>パスワード</FormLabel>
											<PasswordInput
												w="100%"
												autoCapitalize="none"
												onChange={onChange}
												placeholder=""
												onKeyDown={(e) => onClickEnterToSubmit(e)}
											/>
											<Box textAlign={"right"}>
												<ForForgotPasswordUserLink />
											</Box>
										</FormControl>
									)}
									name="password"
								/>
								<ErrorMessage>{errors.password?.message}</ErrorMessage>
								<ErrorMessage mt={8}>{errorMessage}</ErrorMessage>
								{errorComponentDict.password}
							</VStack>
							<VStack spacing={12} textAlign={"center"} w="100%">
								<ButtonWithCustomLoadingComponent
									mt={3}
									py={3}
									w="100%"
									isDisabled={!(watch("email") && watch("password"))}
									type="submit"
									isLoading={isLoggingIn}
									loadingText={
										<TextWithFurigana
											furigana="ろぐいんちゅう"
											color={"#fff"}
											bold
										>
											ログイン中...
										</TextWithFurigana>
									}
								>
									<TextWithFurigana furigana="ろぐいんする" color={"#fff"} bold>
										ログインする
									</TextWithFurigana>
								</ButtonWithCustomLoadingComponent>
								<VStack spacing={3}>
									<LinkButton variant="link" href="/registration">
										<TextWithFurigana
											furigana="とうろくがまだのかたはこちら"
											color={colors.ui.primary[500]}
											bold
										>
											登録がまだの方はこちら
										</TextWithFurigana>
									</LinkButton>
								</VStack>
							</VStack>
						</VStack>
					</form>
					{errorComponentDict.nonFieldErrors}
					{DevLoginButtons}
				</VStack>
			</VStack>
		</FullScreenCenteringLayout>
	)
}
export default LoginScreen
