import { Center, CenterProps } from "@chakra-ui/react"
import { FC } from "react"

const SMALL_WIDTH = 600

type Props = {
	children: React.ReactNode
} & CenterProps

export const FullScreenCenteringLayout: FC<Props> = ({
	children,
	...restCenterProps
}) => (
	<Center minH="100vh" margin="auto" maxW={SMALL_WIDTH} {...restCenterProps}>
		{children}
	</Center>
)
