import {
	Button,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Text,
	VStack
} from "@chakra-ui/react"
import { LinkButton } from "@components/LinkButton"
import { TextWithFurigana } from "@components/TextWithFurigana"
import { colors } from "@styles/colors"
import { FC } from "react"

type Props = {}

export const ForForgotPasswordUserLink: FC<Props> = () => {
	return (
		<Popover>
			<PopoverTrigger>
				<Button variant="ghost" fontSize="xs">
					パスワードを忘れてしまった方はこちら
				</Button>
			</PopoverTrigger>
			<PopoverContent>
				<PopoverArrow />
				<PopoverCloseButton />
				<PopoverHeader>
					<TextWithFurigana
						align="left"
						furigana="あなたのあかうんとのしゅるいはどれですか"
						fontSize="sm"
					>
						{`あなたのアカウントの\n種類はどれですか？`}
					</TextWithFurigana>
				</PopoverHeader>
				<PopoverBody p={0}>
					<VStack alignItems={"flex-start"}>
						<LinkButton
							variant="link"
							href="onetime_login_code_scan"
							{...{
								w: "100%",
								_hover: { bg: colors.blackAlpha[50] },
								p: 3,
								justifyContent: "flex-start"
							}}
						>
							<Text color={colors.ui.primary[500]} fontWeight="bold">
								児童
							</Text>
						</LinkButton>
						<LinkButton
							variant="link"
							href="/password/reset"
							{...{
								w: "100%",
								_hover: { bg: colors.blackAlpha[50] },
								p: 3,
								justifyContent: "flex-start"
							}}
						>
							<Text color={colors.ui.primary[500]} fontWeight="bold">
								教師
							</Text>
						</LinkButton>
						<LinkButton
							variant="link"
							href="/password/reset"
							{...{
								w: "100%",
								_hover: { bg: colors.blackAlpha[50] },
								p: 3,
								justifyContent: "flex-start"
							}}
						>
							<Text color={colors.ui.primary[500]} fontWeight="bold">
								保護者
							</Text>
						</LinkButton>
					</VStack>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}
